/*Gallery Css*/
@import "~react-image-gallery/styles/css/image-gallery.css";

.image-gallery-content.left .image-gallery-slide .image-gallery-image,
.image-gallery-content.right .image-gallery-slide .image-gallery-image {
  max-height: 50vh;
}

/* .image-gallery-image {
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.image-gallery-image:active {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  cursor: zoom-out;
} */

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 4px solid var(--content);
}

.image-gallery-left-nav:hover,
.image-gallery-right-nav:hover,
.image-gallery-play-button:hover,
.image-gallery-fullscreen-button:hover {
  color: var(--content);
}

.image-gallery-svg {
  opacity: 0.2
}
.image-gallery-svg:hover {
  opacity: 1
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}