:root {
  --content: #a03c64;
  --bgColor: #000;
  --text: #fff;
  --black: #000;
  --grey: #777;
  --transparent: rgba(0, 0, 0, 0);
  --white: #fff;
  --size: 16px;
  --btnSize: 18px;
  --border: 1px;
}
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bgColor);
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
label,
li,
span {
  color: var(--text);
}

.main {
  background-color: var(--bgColor);
  // box-shadow: 0px 0px 8px var(--grey);
}
.table-bordered>:not(caption)>* {
  border-width: 1px;
}
.formbackground {
  background-image: url("./images/AuthBG.jpg");
  background-size: cover;
}

.imagetitle {
  background-color: var(--bgColor);
  padding: 5px;
  color: var(--content);
}

// | Tabs

.tab-content {
  padding: 10px;
  border: var(--border) solid var(--content);
}

.card-header-tabs {
  border: var(--border) solid var(--content);
}

.card-header {
  background-color: var(--content);
  border: var(--border) solid var(--content);
  color: var(--text);
  padding-left: 2px;
  padding-right: 2px;
  border: 0px;
}

.card-body {
  background-color: var(--bgColor);
  border: 1px solid var(--content);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background: var(--content);
  border-color: var(--content);
}

.break {
  margin: 0px;
  margin-left: 50px;
  margin-right: 50px;
  border-top: 2px;
}

.card {
  border: 0px solid var(--content);
}

select#formGridState {
  color: var(--text);
  background-color: var(--bgColor);
  border: var(--border) solid var(--content);
  align-items: "flex-end";
  align-content: "flex-end";
}

option {
  margin: 10px;
  padding: 10px;
  size: var(--size);
  font-size: var(--size);
}

.selectOption {
  margin-top: 5px;
  height: 10px;
}

// 1 - Main Section

hr {
  border-bottom:1px solid var(--content);
  opacity: 100%;
}

.title {
  background-color: var(--content);
  background-repeat: repeat;
  background-image: url("./images/double-bubble.png");
  background-blend-mode: color-burn;
  padding: 10px;
  color: var(--text);
  margin-top: 10px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #777;
  text-transform: uppercase;
}

//library carousel css
.inner-carousel {
  // width: 70%; /* Adjust the width as needed */
  margin: 10px auto; /* Center the inner carousel */
}

.inner-carousel .carousel .slide {
  width: auto !important; /* Override inline width to avoid stretching */
}

.inner-carousel .carousel .control-next.control-arrow,
.inner-carousel .carousel .control-prev.control-arrow {
  top: 50%; /* Position arrows vertically centered */
  transform: translateY(-50%);
}

.carousel .control-next.control-arrow::before{
  border-left: 32px solid var(--content) !important;
  border-bottom: 32px solid   transparent !important;
  border-top: 32px solid transparent !important;
}
.carousel .control-prev.control-arrow::before{
  border-right: 32px solid var(--content) !important;
  border-bottom: 32px solid transparent !important;
  border-top: 32px solid transparent !important;
}
.carousel.carousel-slider .control-arrow:hover{
  background: none !important;
}
.carousel .control-dots .dot{
  background: var(--content) !important;
}
.carouselImg{
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin: spx;
}
@media screen and (max-width: 765px) {
  .carouselImg{
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin: 0px;
  }
}


.subtitle {
  display: inline-block;
  background-color: var(--content);
  padding: 10px;
  color: var(--text);

  & a {
    color: var(--text);
    text-decoration: none;
    font-size: var(--size);

    &:hover {
      opacity: 0.5;
    }
  }
}

// 3- DeepXplore

#loading {
  margin-top: 10px;
}

img.img {
  height: 300px;
  border-width: 10px;
  border-color: var(--black);
}

canvas {
  height: 100px;
  width: 10px;
}

.chartjs-render-monitor {
  height: 100px;
  width: 100px;
}

// 4 - Metamorphic Testing
.tablename {
  background: linear-gradient(90deg, var(--content) 0%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a03c64",endColorstr="#000000",GradientType=1);
  padding: 10px;
  color: var(--text);
  font-weight: bold;
  margin-bottom: 0px;
  margin-left: 0px;
  text-transform: uppercase;
}

span.myLink {
  color: var(--text);
  font-size: var(--size);
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
}

// 5 - Navigation

th,
span,
td,
p,
h6,
h5,
h4,
h3,
.nav-link,
.nav-link:hover {
  color: var(--text);
}
.nav-pills .show>.nav-link {
  background-color: var(--content);
}
.dropdown-item.active, .dropdown-item:active{
  background-color: var(--content);
}
//react-lightbox-gallery
.ril__caption{
  justify-content: center !important;
  background: none !important;
}

th {
  font-weight: bold;
  text-transform: uppercase;
}

.h6title {
  color: var(--text);
}

.inner_array {
  padding: 2.5px 5px;
  font-size: medium;
}

// | Buttons
.myBtn {
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 10px;
  -webkit-box-shadow: 0px 1px 3px #666666;
  -moz-box-shadow: 0px 1px 3px #666666;
  box-shadow: 0px 1px 3px #666666;
  font-family: Arial;
  color: var(--white);
  font-size: var(--size2);
  background: var(--content);
  padding: 10px 20px 10px 20px;
  border: solid var(--black) 1px;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
    background-color: var(--content);
  }
  &:focus {
    opacity: 0.7;
    background-color: var(--content);
  }
}

.launch {
  z-index: 3;
}

.launchBtn {
  margin-top: 30px;
  height: 40px;
  width: 150px;
  padding: 0px;
  font-weight: 900;
  font-size: var(--btnSize);
  &:hover {
    border-width: 2px;
  }
}

input.form-control {
  padding-top: 10px;
  padding-bottom: 10px;
}
// Loader

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--content);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  margin: 0;
  position: absolute;
  top: 40%;
  left: 45%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Dataset Selector
.selector {
  text-align: left;
  padding-top: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
}

//Profile

.theme-options {
  display: flex;
  align-items: center;
  float: left;
  max-width: 500px;
  margin: 0 auto;
  text-align: right;
  padding: 20px 0;
  div {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin: 2px 10px;
    border: 1px solid #fff;
    display: inline-block;
    opacity: 0.5;
    &.active {
      opacity: 1;
      border: 2px solid #fff;
      width: 23px;
      height: 23px;
    }
  }
}

.progressbar {
  height: 40px !important;
  margin: 20px;
  border: 1px solid white;
}

span {
  font-size: 10px;
}

.loginform {
  background: black;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 0px 30px 0px 30px;
}

.errormsg {
  color: red;
  padding: 2px;
  font-size: 15px;
}

.checkbox {
  font-size: 14px;
}

.image {
  width: 150px;
  border-radius: 50%;
}

.inputs {
  width: 400px;
}

.profile {
  width: 450px;
  float: left;
}

// | Dropdown

.dropdown-divider,
hr {
  border-color: var(--content);
}

.navBrand {
  color: var(--content) !important;
  font-size: 1.5em;
}

button#dropdown-basic {
  background-color: var(--content);
  color: var(--text);
}

.dropdown-item:hover {
  background-color: var(--content);
  color: var(--text);
}

a.nav-link {
  color: var(--text);
  font-size: var(--size);
  &.active {
    background-color: var(--content);
    color: var(--content);
  }
  &:hover {
    text-decoration: none;
    color: var(--text);
        background-color: var(--content);

  }
}

div.nav-item a.nav-link.active {
  background-color: var(--content);
}

a.dropdown-toggle.nav-link:focus {
  background-color: var(--content);
  color: var(--text);
}

.myButton {
  border: 1px solid var(--text);
  background: var(--content) !important;
  color: var(--text) !important;
  &:hover {
    border: 1px solid var(--content);

    background: var(--text) !important;
    color: var(--content) !important;
  }
  &:focus {
    border: 1px solid var(--text);

    background-color: var(--bgColor) !important;
    color: var(--text) !important;
  }
}

nav.navbar {
  .nav-item.dropdown.show {
    > a {
      color: var(--text) !important;
    }
  }
}

// profile page

.accordion-button {
  text-transform: uppercase;
  height: 70px;
  background-color: var(--content) !important;
  color: var(--text);
  &:focus {
    box-shadow: none;
  }
  &:not(.collapsed) {
    color: var(--text);
  }
  &:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
}
.accordion-body {
  background-color: var(--bgColor) !important;
}

.labels {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 550;
  opacity: 0.8;
}
.fileuploadbtn {
  position: relative;
  padding: 7px 11px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 10px;
  color: #fff;
  transition: 0.2s;
  overflow: hidden;
}

.fileuploadbtn input[type="file"] {
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 0%;
  transform: scale(3);
  opacity: 0;
}

.card-profile-image {
  position: relative;
}

.card-profile-image img {
  position: absolute;
  left: 50%;
  max-width: 180px;
  transition: all 0.15s ease;
  transform: translate(-50%, -30%);
  border-radius: 0.375rem;
}

.card-profile-image img:hover {
  transform: translate(-50%, -33%);
}

.rounded-circle {
  object-fit: cover;
  width: 150px;
  height: 150px;
  border-radius: 50% !important;
}

.form-check-input:checked {
  background-color: var(--content);
  border-color: var(--text);
}

.btn-primary {
  border: 1px solid var(--text);
  background: var(--content) !important;
  color: var(--text) !important;
  &:hover {
    border: 1px solid var(--content);

    background: var(--text) !important;
    color: var(--content) !important;
  }
  &:focus {
    border: 1px solid var(--text);

    background-color: var(--bgColor) !important;
    color: var(--text) !important;
  }
}

.btn-link {
  background: var(--content) !important;
  color: var(--text);
  text-decoration: none;
  margin: 10px 0 20px 0;

  &:hover {
    background: var(--text) !important;
    color: var(--content) !important;
  }
  &:focus {
    background-color: var(--bgColor) !important;
    color: var(--text) !important;
  }
}

@media screen and (max-width: 765px) {
  .col-3 {
    width: 80% !important;
  }
}

// | Structured Pipeline
  .ReactGridGallery_tile-viewport {
    height: auto !important;
    max-width: 500px !important;
    img {
      max-width: 500px !important;
      height: auto !important;
      margin-left: auto !important;
    }
  }

// | Recommendation Pipeline
#performancetesting {
  .req_left_border {
    td {
      border-left: 1px solid var(--text);
    }
  }  

  .table-sm {
    td {
      padding: 0 5px ;
      text-align: center;
    }    
  }

}

// | Segmentation Pipeline
  #ReactGridGallery {
    > div {
      justify-content: center;
    }
  }

img {
  max-width: 500px !important;
}