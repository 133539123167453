.auth__body-container {
    height: 100vh;
    overflow-y: auto;
    background-image: linear-gradient(to bottom,
            rgb(0, 0, 0),
            rgba(0, 0, 0, 0.61)),
        url("../../images/bg.jpg");
    background-size: cover;
  }
  
  .auth__body {
    margin-top: 20px;
    min-height: 70vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .auth__body>div {
    width: 50%;
    max-width: 400px;
  }
  
  .auth__body__left__media {
    width: 100%;
  }
  
  .auth__body__left__media>img {
    width: 100%;
    max-width: 420px;
  }
  
  .auth__body__right {
    width: 100%;
  }
  
  .auth__body__right>.auth__body__right__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
  }
  
  .auth__body__right>.auth__body__right__header>span>a {
    color: #993366;
    text-decoration: none;
    border-bottom: 1px solid #993366;
    font-size: 16px;
  }
  
  .auth__body__right>form.auth__body__form {
    padding: 5px;
  }
  
  .auth__submit__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* padding: 5px; */
  }
  
  .auth__submit__container>label {
    font-style: italic;
    font-size: 14px;
    color: #fff;
    position: relative;
  }
  
  .auth__checkbox {
    margin-right: 5px;
    width: 0;
    height: 0;
  }
  
  span.auth__checkmark {
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #585858;
    transition: all 0.3s ease;
    display: inline-block;
    margin-right: 5px;
  }
  
  span.auth__checkmark:after {
    content: "";
    display: none;
  }
  
  .auth__checkbox:checked  ~ .auth__checkmark {
    background-color: #993366;
    border-color: #fff;
  }
  
  .auth__checkbox:checked  ~ .auth__checkmark:after {
    display: block;
    position: absolute;
    left: 10px;
    width: 6px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  
  
  .auth__checkbox:checked[type="checkbox"] {
    background-color: #993366 !important;
    border-color: #fff;
  }
  
  @media screen and (max-width: 720px) {
    .auth__body {
        flex-direction: column;
    }
  
    .auth__body>div {
        width: 100%;
    }
  
    .auth__body__left {
        display: none;
    }
  }
  
  .auth__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 80px;
    background-color: #000000;
  }
  
  .auth__header__logo>a>img {
    height: 50px;
  }
  
  .form-ele__container {
    position: relative;
  }
  
  .form-ele__container .form-ele__password__show {
    position: absolute;
    right: 0px;
    top: 17px;
    cursor: pointer;
    border-left: 1px solid #585858;
    padding: 10px;
    width: 70px;
    font-size: large;
    color: #adadad;
    background-color: #242424;
    text-align: center;
    border-right: 1px solid #585858;
    border-radius: 0 4px 4px 0;
  }
  
  .form-ele__container>input,
  .form-ele__container>textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #585858;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 16px;
    margin-bottom: 16px;
    resize: none;
    font-size: large;
    background-color: rgba(44, 44, 44, 0.747);
    color: #d3d1d1;
  }
  
  .form-ele__container>input:focus,
  .form-ele__container>textarea:focus {
    outline: none;
  }
  
  
  .form-ele__container>.form-ele__error>p {
    color: red;
    font-size: 12px;
    padding: 0;
    margin: 0;
    margin-top: -10px;
  
  }
  
  button.submit-button {
    background-color: #993366;
    border: none;
    color: white;
    padding: 12px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    font-weight: bolder;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
  
  button.submit-button:hover {
    background-color: #993366;
    opacity: 0.8;
  }
  
  button.submit-button:active {
    background-color: #993366;
    opacity: 0.6;
    outline: none;
  }
  
  button.submit-button:focus {
    outline: none;
  }
  
  button.submit-button:disabled {
    background-color: #858585;
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .auth__footer {
    margin-top: 20px;
    text-align: left;
    padding: 5px 10px;
  }
  
  .auth__footer>a {
    text-decoration: none;
    color: #993366;
    font-size: 16px;
    border-bottom: 1px solid #993366;
  }
  
  .resend-update {
    color: #e6e6e6;
    font-size: 16px;
    text-decoration: none;
    font-style: oblique;
  }
